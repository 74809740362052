import store from "../store";
import {LocationHelper, RequestHelper, ReorderHelper, EmploymentReOrderHelper} from '@/helpers/index';
import {AFFILIATES} from '@/factories';

export default {
    isStartPage() {
        let isStartPage = false;

        let host = LocationHelper.getHost();
        let vehicle_type = RequestHelper.getQueryVariable('vehicle_type');
        if (vehicle_type) {
            isStartPage = true;
        }
        if (AFFILIATES[host] !== undefined) {
            isStartPage = false;

            let vehicleid = RequestHelper.getQueryVariable('vehicleid');
            let vrm = RequestHelper.getQueryVariable('vrm');
            let advertId = RequestHelper.getQueryVariable('advertId');
            let vehicleType = RequestHelper.getQueryVariable('vehicle_type');

            if (vehicleid || vrm || advertId || vehicleType || host === 'comparethemarketcarfinance') {
                isStartPage = true;
            }
        }

        let personalised = RequestHelper.getQueryVariable('personalised');

        if (personalised) {
            isStartPage = false;
        }

        return isStartPage;
    },
    isPersonalised() {
        let personalised = RequestHelper.getQueryVariable('personalised');

        if (personalised) {
            return true;
        }

        return false;
    },
    changeProgressByStage(toName) {
        let totalStage = 8;
        let stageNo = 0;

        const startPageStageNo = this.isStartPage();
        const personalised = this.isPersonalised();

        if(toName) {
            let arr = [
                { toName: "get-my-quote", stageNo: 0 },
                { toName: "start", stageNo: 0 },
                { toName: "finance-details", stageNo: 0 },
                { toName: "contact-details-personalised", stageNo: 0 },
                { toName: "submission-type", stageNo: 0 },
                { toName: "driving-licence", stageNo: startPageStageNo ? 0 : 1 },
                { toName: "marital-status", stageNo: 2 },
                { toName: "date-of-birth", stageNo: 3 },
                { toName: "employment", stageNo: 4 },
                { toName: "address", stageNo: 5 },
                { toName: "amount-to-finance", stageNo: 6 },
                { toName: "car-finance-detail", stageNo: 6 },
                { toName: "bank-detail", stageNo: 6 },
                { toName: "bank-length", stageNo: 6 },
                { toName: "contact-details-p1", stageNo: personalised ? 0 : 7 },
                { toName: "contact-details-p2", stageNo: 8 },
                { toName: "contact-details", stageNo: 8 },
            ];

            if( ReorderHelper.isReorderMulti(['cargurus', 'pistonheads']) || ReorderHelper.isGenericReorder()) {
                let resultArr = ReorderHelper.getProgress('generic');
                if ( resultArr && resultArr.length > 0 ) {
                    arr = resultArr;
                }
            }

            if (EmploymentReOrderHelper.isEmploymentReOrder()) {
                arr = [
                    { toName: "get-my-quote", stageNo: 0 },
                    { toName: "start", stageNo: 0 },
                    { toName: "finance-details", stageNo: 0 },
                    { toName: "contact-details-personalised", stageNo: 0 },
                    { toName: "driving-licence", stageNo: startPageStageNo ? 0 : 1 },
                    { toName: "marital-status", stageNo: 2 },
                    { toName: "date-of-birth", stageNo: 3 },
                    { toName: "address", stageNo: 4 },
                    { toName: "employment", stageNo: 5 },
                    { toName: "amount-to-finance", stageNo: 6 },
                    { toName: "car-finance-detail", stageNo: 6 },
                    { toName: "bank-detail", stageNo: 6 },
                    { toName: "bank-length", stageNo: 6 },
                    { toName: "contact-details-p1", stageNo: personalised ? 0 : 7 },
                    { toName: "contact-details-p2", stageNo: 8 },
                    { toName: "contact-details", stageNo: 8 },
                ];
            }



            if (LocationHelper.getHost() === 'comparethemarketcarfinance' && RequestHelper.getQueryVariable('test') === 'reorder') {
                arr = [
                    { toName: "get-my-quote", stageNo: 0 },
                    { toName: "start", stageNo: 0 },
                    { toName: "finance-details", stageNo: 0 },
                    { toName: "contact-details-personalised", stageNo: 0 },
                    { toName: "submission-type", stageNo: 0 },
                    { toName: "driving-licence", stageNo: startPageStageNo ? 0 : 1 },
                    { toName: "marital-status", stageNo: 2 },
                    { toName: "date-of-birth", stageNo: 3 },
                    { toName: "employment", stageNo: 4 },
                    { toName: "amount-to-finance", stageNo: 5 },
                    { toName: "address", stageNo: 6 },
                    { toName: "car-finance-detail", stageNo: 6 },
                    { toName: "bank-detail", stageNo: 6 },
                    { toName: "bank-length", stageNo: 6 },
                    { toName: "contact-details-p1", stageNo: personalised ? 0 : 7 },
                    { toName: "contact-details-p2", stageNo: 8 },
                    { toName: "contact-details", stageNo: 8 },
                ];
            }

            const isSocial = () => {
                const host = ['social','ecrm', 'tiktok', 'social-premier', 'social-vans', 'social-bad-credit'];
                return host.indexOf(LocationHelper.getHost()) !== -1;
            }

            if (LocationHelper.getHost() === 'ckdirect' || isSocial()) {
                arr = [
                    { toName: "get-my-quote", stageNo: 0 },
                    { toName: "start", stageNo: 0 },
                    { toName: "finance-details", stageNo: 0 },
                    { toName: "contact-details-personalised", stageNo: 0 },
                    { toName: "submission-type", stageNo: 0 },
                    { toName: "driving-licence", stageNo: startPageStageNo ? 0 : 1 },
                    { toName: "marital-status", stageNo: 2 },
                    { toName: "date-of-birth", stageNo: 3 },
                    { toName: "address", stageNo: 4 },
                    { toName: "employment", stageNo: 5 },
                    { toName: "amount-to-finance", stageNo: 6 },
                    { toName: "car-finance-detail", stageNo: 6 },
                    { toName: "bank-detail", stageNo: 6 },
                    { toName: "bank-length", stageNo: 6 },
                    { toName: "contact-details-p1", stageNo: personalised ? 0 : 7 },
                    { toName: "contact-details-p2", stageNo: 8 },
                    { toName: "contact-details", stageNo: 8 },
                ];
            }

            if (store.state.nobankdetails) {
                arr = arr.filter(i => !i.toname.includes("bank-"))
            }
            if (store.state.nopx) {
                arr = arr.filter(i => !i.toname.includes("car-finance-detail"))
            }

            const a = arr.filter(item => toName.indexOf(item.toName) != -1);
            stageNo = EmploymentReOrderHelper.isEmploymentReOrder() ? a[a.length - 1].stageNo : a[0].stageNo;
            
        }
       
        store.dispatch('updateFormValue', [ stageNo/totalStage  , 'progress'])
    }
}