<template>
  <form @submit.prevent="Submit(nextPage)">

    <h1 v-show="isPersonalised">Hi! Let’s start with the basics, what’s your name?</h1>

    <InputGroup
        id="title"
        name="form.titleId"
        :label="isPersonalised ? '' : 'Almost done, let us know who you are?'"
        sub-label="Your title"
        :items="[
        {label: 'Mr', value: 1, id: idConcat(pageName, 'Mr') },
        {label: 'Mrs', value: 2, id: idConcat(pageName, 'Mrs') },
        {label: 'Miss', value: 4, id: idConcat(pageName, 'Miss') },
        {label: 'Ms', value: 3, id: idConcat(pageName, 'Ms') }
      ]"
        v-model="$v.title.$model"
        :v="$v.title"
        :show-tool-tip="showToolTip"
        :mask-input="true"
    />

    <InputText
        :id="idConcat(pageName, 'FirstName')"
        name="form.firstName"
        label="First name"
        autocomplete="off"
        v-model="$v.firstname.$model"
        :v="$v.firstname"
        :sensitive-data="true"
    />

    <InputText
        :id="idConcat(pageName, 'LastName')"
        name="form.lastName"
        label="Last name"
        autocomplete="off"
        v-model="$v.lastname.$model"
        :v="$v.lastname"
        :sensitive-data="true"
    />

    <div v-if="isPersonalised">
      <IconButtonContinue :id="idConcat(pageName, 'IconContinueBtn')" :sub-label="$t('page.finance_details.button_continue.sub_label')" :label="isClassifyPartner ? 'Continue' : 'Proceed'" />
      <div style="font-size: 0.9em; text-align: center;" v-html="$t('page.finance_details.message.label')"></div>
      <PrivacyBanner :id="idConcat(pageName, 'PrivacyBannerHref')"  :label="$t('page.finance_details.privacy_banner.label')" />
      <div style="font-size: 0.9em; text-align: center;" v-html="$t('page.finance_details.trade.label')"></div>
      <PowerByCf247Text />
      <RepExample></RepExample>
      <DisclosureStatement/>
      <div v-show="showAppDuration" class="app-duration">
        <div>
          <img src="@/assets/img/checked_1.png" width="20" class="checked" />
        </div>
        <div>
          Your no-obligation quote is just 1 minute away!
        </div>
      </div>
    </div>

    <div v-else>
      <ButtonContinue v-show="!getYourQuoteButton" :id="idConcat(pageName,'ContinueBtn')" />
      <ButtonGetYourQuote v-show="getYourQuoteButton" :id="idConcat(pageName,'GetYourQuoteBtn')" />
      <ButtonBack :id="idConcat(pageName,'BackBtn')" />

      <FraudPreventionMsg :id="idConcat(pageName,'FraudPreventionMsgHref')" @showModal="openModal" />

      <Modal v-show="isTermsVisible" @close="closeModal">
        <template v-slot:body>
          <Terms @openModal="openModal" />
        </template>
        <Terms />
      </Modal>

      <Modal v-show="isDealershipsVisible" @close="closeModal">
        <template v-slot:body>
          <Dealerships />
        </template>
        <Terms />
      </Modal>

      <Modal v-show="isPrivacyVisible" @close="closeModal">
        <template v-slot:body>
          <Privacy @openModal="openModal" />
        </template>
        <Terms />
      </Modal>

      <Modal v-show="isCookieVisible" @close="closeModal">
        <template v-slot:body>
          <Cookie />
        </template>
        <Terms />
      </Modal>
    </div>
    
    <InfoFooter :showTopOnly="true" />
  </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import FraudPreventionMsg from '@/components/FraudPreventionMsg'
import { debounce } from 'lodash'
import { SegmentService } from '../services';
import { phoneNormalised } from '../helpers/phone-normalization-helper'
import { checkFieldWithoutHtmlTag } from '../modules/checkFieldWithoutHtmlTag';
import Terms from '@/components/Terms.vue';
import Dealerships from '@/components/Dealerships.vue';
import Privacy from '@/components/Privacy.vue';
import Cookie from '@/components/Cookie.vue';
import {LocationHelper, RequestHelper} from '@/helpers';
import {AFFILIATES} from '@/factories';
import RepExample from '@/components/RepExample.vue';
import PowerByCf247Text from '@/components/PowerByCf247Text.vue';
import DisclosureStatement from '@/components/DisclosureStatement.vue';
import IconButtonContinue from '@/components/IconButtonContinue.vue';
import { SENSITIVE_DATA } from '../const';

export default {
  components: {
    IconButtonContinue,
    DisclosureStatement, PowerByCf247Text, RepExample,
    Terms,
    Dealerships,
    Privacy,
    Cookie,
    FraudPreventionMsg,
  },
  data() {
    return {
      pageName: RequestHelper.getQueryVariable('personalised') ? 'ContactDetailsPersonalisedCro' : 'ContactDetailsP1',
      title: this.$store.state.form.titleId,
      firstname: this.$store.state.form.firstName,
      lastname: this.$store.state.form.lastName,
      isTermsVisible: false,
      isDealershipsVisible: false,
      isPrivacyVisible: false,
      isCookieVisible: false,
      SENSITIVE_DATA: SENSITIVE_DATA
    }
  },
  computed: {
    nextPage() {
       if (this.isPersonalised) {
         return { name: 'finance-details' }
       }

      return { name: 'contact-details-p2' , params: {  }}
    },
    showToolTip() {
      return LocationHelper.getHost() === 'comparethemarketcarfinance'
    },
    getYourQuoteButton() {
        return this.$route.query.getYourQuoteButton === '1';
    },
    showAppDuration() {
      let appDuration = true;

      let host = LocationHelper.getHost();

      if (AFFILIATES[host] !== undefined) {
        appDuration = false;
      }

      return appDuration;
    },
    isPersonalised() {
      let host = LocationHelper.getHost();

      let personalised = RequestHelper.getQueryVariable('personalised');

      return personalised && AFFILIATES[host] === undefined;
    },
    isClassifyPartner() {
      let host = LocationHelper.getHost();

      if (AFFILIATES[host] !== undefined){
        const affiliateConfig = AFFILIATES[host];

        if (affiliateConfig.classifiedPartner) {
          return affiliateConfig.classifiedPartner;
        }
      }

      return false;
    },
  },
  validations: {
    title: {
      required
    },
    firstname: {
      required,
      checkFieldWithoutHtmlTag
    },
    lastname: {
      required,
      checkFieldWithoutHtmlTag
    },
  },
  mounted() {
    this.$store.dispatch('updateFormValue', [ this.pageName , 'pageName']);
    
    this.$store.dispatch('updateFormValue', [true, 'form.personalDetailsCompleted'])
  },
  methods: {
    openModal(modal) {
      this.isTermsVisible = false;

      if (modal === 'privacy-policy') {
        this.isPrivacyVisible = true;
      }

      if (modal === 'cookie-policy') {
        this.isCookieVisible = true;
      }
    },
    completeApplication() {
      this.submitPressed = true

      this.$v.$touch();

      if (this.$v.terms.$model && !this.$v.$invalid) {
        SegmentService.trackPersonalDetailsCompletedEvent(this.$store.state, {
          guid: this.$store.state.GUID,
          email: this.email,
          date_of_birth: this.$store.state.form.dateOfBirth,
          first_name: this.firstname,
          last_name: this.lastname,
          phone_number: this.phone,
          phone_number_normalised: phoneNormalised(this.phone),
          postcode: this.$store.state.form.addresses[0].postcode || null,
          vehicle_type_id: this.$store.state.form.vehicleTypeId,
          utm: this.$store.state.form.utmParameters,
          net_monthly_income: this.$store.state.form.netMonthlyIncome,
          city: this.$store.state.form.addresses[0].town || null,
          gender: this.$store.state.form.titleId === 1 ? 'male' : 'female'
        });
        
        const self = this;
        self.isSubmitted = true;
        setTimeout(function () {
          self.isSubmitted = false;
        }, 10000)

        let data = [true, 'form.IsCompleted']
        this.$store.dispatch('updateFormValue', data)
            .then(() => this.$emit('sendFullApp', this.$store.state))
      }
    },
    created() {
      let self = this;
      self.completeApplication = debounce(self.completeApplication, 500)
    }
  }
}
</script>
<style lang="scss" scoped>
#app form {
  .duel-inputs {
    display: flex;
    justify-content: space-between;

    .small-input {
      max-width: calc(50% - 20px)
    }
  }
}

.app-duration {
  display: flex;
  justify-content: center;
  margin-top: 25px;

  div {
    font-size: 1.15em;
    line-height: 30px;
  }
}
.checked {
  margin-right: 15px;
}
</style>
