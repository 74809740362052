import { RequestHelper } from '.';
import { AFFILIATES, DEALERS } from '../factories';

export default {
    getHost() {
        let domainname = location.host;

        let host = domainname.split('.')[0];

        // Check DomainName match any Affiliate DomainName and set Host If find
        Object.keys(AFFILIATES).forEach(function(key) {
            if(typeof AFFILIATES[key].domainNames !== 'undefined' &&
                AFFILIATES[key].domainNames !== null &&
                Array.isArray(AFFILIATES[key].domainNames) &&
                AFFILIATES[key].domainNames.includes(domainname)) {
                    host = key;
            }
        });


        // Check DomainName match any Dealer DomainName and set Host If find
        Object.keys(DEALERS).forEach(function(key) {
            if(typeof DEALERS[key].domainNames !== 'undefined' &&
                DEALERS[key].domainNames !== null &&
                Array.isArray(DEALERS[key].domainNames) &&
                DEALERS[key].domainNames.includes(domainname)) {
                host = key;
            }
        });

        if(domainname.includes('-form.'))
            host = domainname.split('.')[0].replace('-form', '')

        if(domainname.includes('-exp.'))
            host = domainname.split('.')[0].replace('-exp', '')

        if(domainname.includes('-rc.'))
            host = domainname.split('.')[0].replace('-rc', '')

        if(domainname.includes('-qa.'))
            host = domainname.split('.')[0].replace('-qa', '')

        const aid = RequestHelper.getQueryVariable('aid')
        const aidIndex = RequestHelper.getQueryVariable('aid_index')

        if(aid) {
            const hosts = [];
            Object.keys(AFFILIATES).forEach(function(key) {
                if(AFFILIATES[key].affiliateUserId === Number(aid)) {
                    hosts.push(key);
                }
            });

            if(hosts.length > 0) {
                host = aidIndex ? hosts[aidIndex] : hosts[0];
            }
        }
        
        return host;
    }
}
