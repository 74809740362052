<template>
  <form id="Form" @submit.prevent="Submit(nextPage())">
    <h1>Could you provide us your finance details?</h1>

    <InputGroup
        id="term"
        name="form.vehicleFinance.term"
        sub-label="Term"
        :items="[
        {label: '24', value: 24, id: idConcat(pageName, '24') },
        {label: '36', value: 36, id: idConcat(pageName, '36') },
        {label: '48', value: 48, id: idConcat(pageName, '48') },
        {label: '60', value: 60, id: idConcat(pageName, '60') }
      ]"
        v-model="$v.term.$model"
        :v="$v.term"
        :mask-input="true"
    />

    <InputCurrency
        :id="idConcat(pageName,'Deposit')"
        name="form.vehicleFinance.deposit"
        label="Deposit"
        v-model="$v.deposit.$model"
        :v="$v.deposit"
        bigLabel
        :appearDisabled=true
        :min-borrow-threshold="0"
    />

    <InputCurrency
        :id="idConcat(pageName,'PartExchangeValue')"
        name="form.vehicleFinance.partExchangeValue"
        label="Part Exchange Value"
        v-model="$v.partExchangeValue.$model"
        :v="$v.partExchangeValue"
        bigLabel
        :appearDisabled=true
        :min-borrow-threshold="0"
    />

    <InputCurrency
        :id="idConcat(pageName,'PartExchangeSettlement')"
        name="form.vehicleFinance.partExchangeSettlement"
        label="Part Exchange Settlement"
        v-model="$v.partExchangeSettlement.$model"
        :v="$v.partExchangeSettlement"
        bigLabel
        :appearDisabled=true
        :min-borrow-threshold="0"
    />

    <InputMiles
        :id="idConcat(pageName,'EstimatedAnnualMileage')"
        name="form.vehicleFinance.estimatedAnnualMileage"
        label="Estimated Annual Mileage"
        v-model="$v.estimatedAnnualMileage.$model"
        :v="$v.estimatedAnnualMileage"
        bigLabel
        :appearDisabled=true
        :min-borrow-threshold="0"
    />

    <ButtonContinue :id="idConcat(pageName,'ContinueBtn')">Continue</ButtonContinue>
    <ButtonBack :id="idConcat(pageName,'BackBtn')" />
    <InfoFooter :showTopOnly="true" />
  </form>
</template>

<script>
import {required} from 'vuelidate/lib/validators';
import InputCurrency from '@/components/InputCurrency.vue';
import InputMiles from '@/components/InputMiles.vue';

export default {
  components: {
    InputCurrency,
    InputMiles
  },
  data() {
    return {
      pageName: 'CarFinanceDetail',
      term: this.$store.state.form.vehicleFinance.term,
      deposit: this.$store.state.form.vehicleFinance.deposit,
      partExchangeValue: this.$store.state.form.vehicleFinance.partExchangeValue,
      partExchangeSettlement: this.$store.state.form.vehicleFinance.partExchangeSettlement,
      estimatedAnnualMileage: this.$store.state.form.vehicleFinance.estimatedAnnualMileage,
      isDealer: this.$store.state.isDealer,
      noBankDetails: this.$store.state.noBankDetails
    }
  },
  validations: {
    term: {
      required
    },
    deposit: {},
    partExchangeValue: {},
    partExchangeSettlement: {},
    estimatedAnnualMileage: {},
  },
  computed: {
  },
  methods: {
      nextPage: function () {
        return this.isDealer && this.noBankDetails ? { name: 'contact-details-p1' } : { name: 'bank-detail' }
    }
  },
  mounted() {
    this.$store.dispatch('updateFormValue', [ this.pageName , 'pageName']);
    
    this.$store.dispatch('updateFormValue', [true, 'form.carFinanceDetailsCompleted'])
  }
}
</script>
