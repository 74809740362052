<template>
  <div :class="className + ' loaneoAdjust' ">
    <CarcraftLogo v-if="isCarcraft"/>
    <ParkersLogo v-if="isParkers"/>
    <ThinkMoneyLogo v-if="isThinkMoneyCar"/>
    <OceanFinanceLogo v-if="isOceanCar"/>
    <CreditKarma v-if="isCreditKarma"/>
    <CKDirect v-if="isCKDirect"/>
    <CredAbility v-if="isCredAbility"/>
    <AllPurposeLoans v-if="isAllPurposeLoans"/>
    <GoWizardLogo v-if="isGoWizard"/>
    <DesperatesellerLogo v-if="isDesperateseller"/>
    <CarsiteLogo v-if="isCarsite"/>
    <AutoVillageLogo v-if="isAutoVillage" />
    <CarCredibleLogo v-if="isCarCredible" />
    <CarsnitchLogo v-if="isCarsnitch" />
    <HonestJohnLogo v-if="isHonestJohn" />
    <YoungCarDriverLogo v-if="isYoungcardriver"/>
    <ConfusedLogo v-if="isConfused"/>
    <EquifaxLogo v-if="isEquifax"/>
    <ClearscoreLogo v-if="isClearscore"/>
    <DragonFinanceLogo v-if="isDragonFinance" />
    <CarGurusLogo v-if="isCarGurus" />
    <ExperianLogo v-if="isExperian" />
    <FirstCentralInsuranceLogo v-if="isFirstCentralInsurance" />
    <CheapCarsForSaleLogo v-if="isCheapCarsForSale" />
    <DriveScoreLogo v-if="isDriveScore" />
    <MyCarCheckLogo v-if="isMyCarCheck" />
    <CompareTheMarketLogo v-if="isCompareTheMarket" />
    <CompareTheMarketCarFinanceLogo v-if="isCompareTheMarketCarFinance" />
    <LoaneoLogo v-if="isLoaneo" />
    <PistonHeadsLogo v-if="isPistonHeads" />
    <WilliamsLogo v-if="isWilliams" />
    <RacCarsLogo v-if="isRacCars" />
    <PreFinanceLogo v-if="isPreFinance" />
  </div>
</template>

<script>
  import {LocationHelper} from '@/helpers';
  import CarCredibleLogo from '@/components/Affiliates/CarCredibleLogo';
  import CarsnitchLogo from '@/components/Affiliates/CarsnitchLogo';
  import GoWizardLogo from '@/components/Affiliates/GoWizardLogo';
  import CreditKarma from '@/components/Affiliates/CreditKarma';
  import CKDirect from '@/components/Affiliates/CKDirect';
  import ParkersLogo from '@/components/Affiliates/ParkersLogo';
  import CarcraftLogo from '@/components/Affiliates/CarcraftLogo';
  import ThinkMoneyLogo from '@/components/Affiliates/ThinkMoneyLogo';
  import OceanFinanceLogo from '@/components/Affiliates/OceanFinanceLogo';
  import CredAbility from '@/components/Affiliates/CredAbility';
  import AllPurposeLoans from '@/components/Affiliates/AllPurposeLoans';
  import DesperatesellerLogo from '@/components/Affiliates/DesperatesellerLogo';
  import CarsiteLogo from '@/components/Affiliates/CarsiteLogo';
  import AutoVillageLogo from '@/components/Affiliates/AutoVillageLogo';
  import HonestJohnLogo from '@/components/Affiliates/HonestJohnLogo';
  import YoungCarDriverLogo from '@/components/Affiliates/YoungCarDriverLogo';
  import ConfusedLogo from '@/components/Affiliates/ConfusedLogo';
  import EquifaxLogo from '@/components/Affiliates/EquifaxLogo.vue';
  import ClearscoreLogo from '@/components/Affiliates/ClearscoreLogo.vue';
  import DragonFinanceLogo from '@/components/Affiliates/DragonFinanceLogo.vue';
  import CarGurusLogo from '@/components/Affiliates/CarGurusLogo.vue';
  import ExperianLogo from '@/components/Affiliates/ExperianLogo.vue';
  import FirstCentralInsuranceLogo from '@/components/Affiliates/FirstCentralInsuranceLogo.vue';
  import CheapCarsForSaleLogo from '@/components/Affiliates/CheapCarsForSale.vue';
  import DriveScoreLogo from '@/components/Affiliates/DriveScoreLogo.vue';
  import MyCarCheckLogo from '@/components/Affiliates/MyCarCheckLogo.vue';
  import CompareTheMarketLogo from '@/components/Affiliates/CompareTheMarketLogo.vue';
  import CompareTheMarketCarFinanceLogo from '@/components/Affiliates/CompareTheMarketCarFinanceLogo.vue';
  import LoaneoLogo from '@/components/Affiliates/LoaneoLogo.vue';
  import PistonHeadsLogo from '@/components/Affiliates/PistonHeadsLogo.vue';
  import WilliamsLogo  from '@/components/Affiliates/WilliamsLogo.vue';
  import RacCarsLogo  from '@/components/Affiliates/RacCarsLogo.vue';
  import PreFinanceLogo  from '@/components/Affiliates/PreFinanceLogo.vue';

  export default {
    name: "AffiliateLogo",
    props: {
      className: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        isCarcraft: LocationHelper.getHost().startsWith('carcraft'),
        isParkers: LocationHelper.getHost() === 'parkers' || LocationHelper.getHost() === 'parkers-submission',
        isThinkMoneyCar: LocationHelper.getHost() === 'thinkmoneycar',
        isOceanCar: LocationHelper.getHost() === 'oceancar' || LocationHelper.getHost() === 'oceancar-submission',
        isCreditKarma: LocationHelper.getHost() === 'ckdirect' || LocationHelper.getHost() === 'ckdirect-whitelabel',
        isCKDirect: LocationHelper.getHost() === 'ckdirect-submission' || LocationHelper.getHost() === 'ckdirect-submission-whitelabel',
        isCredAbility: LocationHelper.getHost() === 'credapp',
        isAllPurposeLoans: LocationHelper.getHost() === 'allpurposeloans',
        isGoWizard: LocationHelper.getHost() === 'gowizard',
        isDesperateseller: LocationHelper.getHost() === 'desperateseller',
        isCarsite: LocationHelper.getHost() === 'carsite',
        isAutoVillage: LocationHelper.getHost() === 'autovillage',
        isCarCredible: LocationHelper.getHost() === 'carcredible',
        isCarsnitch: LocationHelper.getHost() === 'carsnitch',
        isHonestJohn: LocationHelper.getHost() === 'honestjohn',
        isYoungcardriver: LocationHelper.getHost() === 'youngcardriver',
        isConfused: LocationHelper.getHost() === 'confused',
        isEquifax: LocationHelper.getHost() === 'equifax',
        isClearscore: LocationHelper.getHost() === 'clearscore',
        isDragonFinance: LocationHelper.getHost() === 'dragonfinance',
        isCarGurus:  LocationHelper.getHost() === 'cargurus-submission',
        isExperian: LocationHelper.getHost() === 'experian',
        isFirstCentralInsurance: LocationHelper.getHost() === 'firstcentralinsurance' || LocationHelper.getHost() === '1stcentral',
        isCheapCarsForSale: LocationHelper.getHost() === 'cheapcarsforsale',
        isDriveScore: LocationHelper.getHost() === 'drivescore',
        isMyCarCheck: LocationHelper.getHost() === 'mycarcheck',
        isCompareTheMarket: LocationHelper.getHost() === 'comparethemarket',
        isCompareTheMarketCarFinance: LocationHelper.getHost() === 'comparethemarketcarfinance',
        isLoaneo: LocationHelper.getHost() === 'loaneo',
        isPistonHeads: LocationHelper.getHost() === 'pistonheads',
        isWilliams: LocationHelper.getHost() === 'williams',
        isRacCars: LocationHelper.getHost() === 'raccars',
        isPreFinance: LocationHelper.getHost() === 'prefinance',
      }
    },

    components: {
      CompareTheMarketCarFinanceLogo,
      CompareTheMarketLogo,
      CheapCarsForSaleLogo,
      FirstCentralInsuranceLogo,
      ExperianLogo,
      ClearscoreLogo,
      EquifaxLogo,
      CarCredibleLogo,
      CarsnitchLogo,
      GoWizardLogo,
      CreditKarma,
      CKDirect,
      ParkersLogo,
      CarcraftLogo,
      ThinkMoneyLogo,
      OceanFinanceLogo,
      CredAbility,
      AllPurposeLoans,
      DesperatesellerLogo,
      CarsiteLogo,
      AutoVillageLogo,
      HonestJohnLogo,
      YoungCarDriverLogo,
      ConfusedLogo,
      DragonFinanceLogo,
      CarGurusLogo,
      DriveScoreLogo,
      MyCarCheckLogo,
      LoaneoLogo,
      PistonHeadsLogo,
      WilliamsLogo,
      RacCarsLogo,
      PreFinanceLogo
    },
  }
</script>

<style scoped>
  .loaneoAdjust {
    display: flex; 
    justify-content: center;
    align-items: center;
  }
</style>