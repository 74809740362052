<template>
  <form id="Form" @submit.prevent="beforeSubmit();Submit({ name: startPage  })">

    <input type="hidden" name="dummy" value="something">

    <h1 class="get-quote-title" style="text-align: center;">
      <span v-if="isCarCraft">
        Get my finance quote with Car Finance 247
      </span>
      <span v-else>
        Get a quote on this vehicle with Car Finance 247
      </span>
    </h1>
    <div style="text-align: center;" class="privacy-banner get-quote-text">
      <span v-if="isCarCraft" class="get-quote-subtitle">
        {{ partnerName }} have partnered with Car Finance 247, a leading credit broker who will get you the best deal from their wide panel of lenders.
      </span>
      <span v-else class="get-quote-subtitle">
        {{ partnerName }} have partnered with Car Finance 247, a leading credit broker who will find you the best deal from their wide panel of lenders.
      </span>
    </div>

    <!-- <section v-if="showCommissionMsg">
        <div style="padding: 0px;border-radius: 5px;font-size: .9em;text-align: center;margin-top: 20px;margin-bottom: 20px;"> -->
        <div  v-if="showCommissionMsg" style="text-align: center;margin-top: 20px;margin-bottom: 20px;" class="privacy-banner get-quote-text">
          <span class="get-quote-subtitle">
            <b>Car Finance 247</b> will pay us a commission for introducing a customer who takes out a motor finance agreement. The commission paid is a <b>fixed amount</b> per agreement that is directly linked to the amount you borrow. 
          </span>
        </div>
    <!-- </section> -->

    <ButtonContinue :id="idConcat(pageName, 'IconContinueBtn')" :sub-label="$t('page.finance_details.button_continue.sub_label')" label="Continue" />
    <div style="font-size: 0.9em; text-align: center;" v-html="$t('page.finance_details.message.label')"></div>
    <PrivacyBanner :id="idConcat(pageName, 'PrivacyBannerHref')" v-show="false" :label="$t('page.finance_details.privacy_banner.label')" />
    <section v-if="showPrivacyBannerQuote">
      <div class="privacy-banner-quote">
        <span>Rates from 9.9% APR. Representative APR 19.9% <br />Car Finance 247 is a credit broker, not a lender. </span>
      </div>
    </section>
    <div style="font-size: 0.9em; text-align: center;" v-html="$t('page.finance_details.trade.label')"></div>
    <PowerByCf247Text />
    <RepExample></RepExample>
    <DisclosureStatement/>
    <div v-show="showAppDuration" class="app-duration">
        <div>
            <img src="@/assets/img/checked_1.png" width="20" class="checked" />
        </div>
        <div>
            Your no-obligation quote is just 1 minute away!
        </div>
    </div>

    <InfoFooter />

  </form>


</template>

<script>
import RepExample from "@/components/RepExample";
import DisclosureStatement from '@/components/DisclosureStatement';
import PowerByCf247Text from '@/components/PowerByCf247Text';
import {AffiliatesHelper, LocationHelper, RequestHelper} from '@/helpers';
import {AFFILIATES} from '@/factories';
import store from "../store";


export default {
  components: {
    DisclosureStatement,
    RepExample,
    PowerByCf247Text,
  },
  data() {
    return {
      pageName: 'GetMyQuote',
      isCarCraft: LocationHelper.getHost() === 'carcraft',
    }
  },
  methods: {
    beforeSubmit: () =>{
      store.dispatch('updateFormValue', [ true , 'fromGetMyQuote']);
    }
  },
  computed: {
    showCommissionMsg() {
      return ['cargurus','pistonheads'].includes(LocationHelper.getHost());
    },
    showPrivacyBannerQuote() {
      const host = [
        'pistonheads',
      ]
      return host.includes(LocationHelper.getHost());
    },
    showAppDuration() {
      let appDuration = true;

      let host = LocationHelper.getHost();

      if (AFFILIATES[host] !== undefined) {
        appDuration = false;
      }

      return appDuration;
    },
    startPage() {
      let nextPage = 'finance-details';

      let host = LocationHelper.getHost();

      let personalised = RequestHelper.getQueryVariable('personalised');

      if (personalised) {
        if (AFFILIATES[host] !== undefined) {
          nextPage = 'contact-details-personalised';
        } else {
          nextPage = 'contact-details-p1';
        }
      }

      return nextPage;
    },
    partnerName() {
      return AffiliatesHelper.getPartnerName(this.$store.state);
    },
    iOS() {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
  },
  mounted() {
    store.dispatch('updateFormValue', [ this.pageName , 'pageName']);

    let vehicle_type = RequestHelper.getQueryVariable('vehicle_type');

    if (vehicle_type) {
      this.startPage = 'driving-licence';
    }

    let host = LocationHelper.getHost();

    if (AFFILIATES[host] !== undefined) {
      const affiliateConfig = AFFILIATES[host];

      if (affiliateConfig) {
        this.startPage = 'driving-licence';
      }

      if (affiliateConfig && typeof affiliateConfig.showVehicleType !== "undefined") {
        this.showVehicleType = affiliateConfig.showVehicleType;
      }

      if (affiliateConfig && typeof affiliateConfig.startPage !== "undefined") {
        this.startPage = affiliateConfig.startPage;
      }
    }

    let personalised = RequestHelper.getQueryVariable('personalised');

    if (personalised) {
      if (AFFILIATES[host] !== undefined) {
        this.startPage = 'contact-details-personalised';
      } else {
        this.startPage = 'contact-details-p1';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .app-duration {
      display: flex;
      justify-content: center;
      margin-top: 25px;

      div {
        font-size: 1.15em;
        line-height: 30px;
      }
  }
  .checked {
      margin-right: 15px;
  }
</style>


<style>

@media (max-width: 360px) {
  .get-quote-title {
    font-size: 1.3em;
    margin: 5px 0px;
  }
}

.get-quote-subtitle {
  font-size: 0.9em;
}

@media (max-width: 360px) {
  .get-quote-subtitle {
    font-size: 0.8em;
  }
}

.privacy-banner-quote {
  padding: 0px;
  /* background: $gray; */
  border-radius: 5px;
  font-size: .9em;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media (min-width: 1024px) {
  margin-top: 20px;
  font-size: 1.125rem;
  text-align: left;
  padding: 15px 50px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
  .get-quote-title {
    line-height: 1.23em;
  }
}

@media only screen and (-webkit-max-device-pixel-ratio: 2) {
  .get-quote-title {
    line-height: 1.22em;
  }
}

  @media (max-height: 740px) {
    .get-quote-title {
      font-size: 1.5em;
      margin: 10px 0 10px;
        text-align: justify;
      text-justify: inter-word;
    }
    .get-quote-subtitle {
      font-size: 0.8em;
    }

    .privacy-banner-quote {
      font-size: 0.8em;
    }
  }

  @media (max-width: 320px) {
    .get-quote-title {
      font-size: 1.3em;

    }
  }
</style>