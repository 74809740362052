import axios from 'axios'
import { ErrorService, CloudinaryService } from '.'
import { URLS } from '../factories'
import { ObjectHelper } from '../helpers'

function getVehicleById(vehicleId, loadVehicleFromQuote, state) {
    let tokenString = 'Bearer ' + state.token.authenticationToken
    let headersObj = {"Authorization": tokenString}

    if (loadVehicleFromQuote) {
        const vehicleApiUrl = state.isProduction ? URLS.production.vehicleApiUrl : URLS.replica.vehicleApiUrl;

        return axios({
            method: 'GET',
            url: vehicleApiUrl + '/v1/vehicle/application',
            headers: headersObj,
            params: { vehicleId: vehicleId, applicationFormVehicleTypeId: 1 }
        });
    } else {
        const vehicleSearchApiUrl = state.isProduction ? URLS.production.vehicleSearchApiUrl : URLS.replica.vehicleSearchApiUrl;

        return axios({
            method: 'GET',
            url: vehicleSearchApiUrl + '/v1/cars',
            headers: headersObj,
            params: { vehicleId: vehicleId }
        });
    }

}

function getVehicleByVrm(vrm, state) {
    let tokenString = 'Bearer ' + state.token.authenticationToken
    let headersObj = { "Authorization": tokenString }

    const vehicleSearchApiUrl = state.isProduction ? URLS.production.vehicleSearchApiUrl : URLS.replica.vehicleSearchApiUrl;

    return axios({
        method: 'GET',
        url: vehicleSearchApiUrl + '/v1/cars/registration?registration='+vrm.toUpperCase(),
        headers: headersObj
    });

}

function setFormVehicle(store, vrm, fuelType, regYear, transmission, imageUrl) {
    store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(vrm, null), 'form.vehicle.vrm']);
    store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(fuelType, null), 'form.vehicle.fuel']);
    store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(regYear, null), 'form.vehicle.year']);
    store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(transmission, null), 'form.vehicle.transmission']);
    store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(imageUrl, null), 'form.vehicle.imageUrl']);
}

export default {
    loadVehicle(affiliateConfig, state, store) {
        const loadVehicleFromQuote = affiliateConfig && affiliateConfig.loadVehicleFromQuote === true ? true : false;

        getVehicleById(state.vehicleId, loadVehicleFromQuote, state).then(function (response) {
            const responseData = response.data;

            let imageUrl = ''

            store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(state.vehicleId, null), 'form.vehicle.vehicleId']);

            if (loadVehicleFromQuote) {
                imageUrl = responseData['imageUrl'];
                setFormVehicle(store, responseData['vrm'], responseData['fuelType'], responseData['regYear'], responseData['transmission'], responseData['imageUrl']);
            } else {
                imageUrl = responseData['images'] ? responseData['images'][0] : undefined;

                setFormVehicle(store, responseData['regNumber'], responseData['fuel'], responseData['year'], response['trans'], imageUrl);
            }

            if (state.form.vehicle.imageUrl && state.form.vehicle.imageUrl.indexOf('cloudinary') === -1) {
                const cloudinaryVehicleImageUrl = CloudinaryService.getCloudinaryImageUrl(state.form.vehicle.imageUrl);
                store.dispatch('setCloudinaryVehicleImageUrl', cloudinaryVehicleImageUrl);
            }

            store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(responseData['make'], null), 'form.vehicle.make']);
            store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(responseData['model'], null), 'form.vehicle.model']);
            store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(responseData['price'], null), 'form.vehicle.price']);
            store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(responseData['mileage'], null), 'form.vehicle.mileage']);
            store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(responseData['colour'], null), 'form.vehicle.colour']);
            store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(imageUrl, null), 'form.vehicle.imageUrl']);
            store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(responseData['vdpUrl'], null), 'form.vehicle.vdpUrl']);
            store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(responseData['dealerInfoUrl'], null), 'form.vehicle.dealerInfoUrl']);
        }).catch(function (error) {
            ErrorService.submit(state, error.message, error.stack);
        });
    },
    loadVehicleByVrm(state, store) {
        getVehicleByVrm(state.vrm, state).then(function (response) {
            const responseData = response.data;

            let imageUrl = ''

            store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(state.vehicleId, null), 'form.vehicle.vehicleId']);
            
            imageUrl = responseData['images'] ? responseData['images'][0] : undefined;

            setFormVehicle(store, responseData['regNumber'], responseData['fuel'], responseData['year'], response['trans'], imageUrl);
       
            if (state.form.vehicle.imageUrl && state.form.vehicle.imageUrl.indexOf('cloudinary') === -1) {
                const cloudinaryVehicleImageUrl = CloudinaryService.getCloudinaryImageUrl(state.form.vehicle.imageUrl);
                store.dispatch('setCloudinaryVehicleImageUrl', cloudinaryVehicleImageUrl);
            }

            store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(responseData['make'], null), 'form.vehicle.make']);
            store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(responseData['model'], null), 'form.vehicle.model']);
            store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(responseData['price'], null), 'form.vehicle.price']);
            store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(responseData['mileage'], null), 'form.vehicle.mileage']);
            store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(responseData['colour'], null), 'form.vehicle.colour']);
            store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(imageUrl, null), 'form.vehicle.imageUrl']);
            store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(responseData['vdpUrl'], null), 'form.vehicle.vdpUrl']);
            store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(responseData['dealerInfoUrl'], null), 'form.vehicle.dealerInfoUrl']);
            store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(responseData['dealerInfoUrl'], null), 'form.vehicle.dealerInfoUrl']);

        }).catch(function (error) {
            ErrorService.submit(state, error.message, error.stack);
        });
    }
}