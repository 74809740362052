<template>
    <div class="loaneoAdjust">
    <Logo v-if="showCF247Logo && !showPowerBy"></Logo>
    <img v-if="showCF247Logo && showPowerBy" height="30"  src="../../assets/img/logos/New_CarFinance247_PowerBy_Logo.png" />
    </div>
</template>

<script>
    import {LocationHelper} from '@/helpers';
    import {AFFILIATES} from '@/factories';
    import Logo from "../Logo";

    export default {
        name: "PowerBy",
        components: {
            Logo
        },
        props: {
          callByModel: {
            type: Boolean,
            default: false,
          }
        },
        data() {
          return {
            showPowerBy: true,
            showCF247Logo: true,
          }
        },
        mounted() {
          let host = LocationHelper.getHost();

          if (AFFILIATES[host] !== undefined) {
            const affiliateConfig = AFFILIATES[host];

            if (affiliateConfig && typeof affiliateConfig.showPowerBy !== "undefined") {
              if(!this.callByModel) {
                this.showPowerBy = affiliateConfig.showPowerBy;
              }
              
              if(this.callByModel) {
                if (affiliateConfig && typeof affiliateConfig.useModelV3 !== "undefined") {
                  if (affiliateConfig && typeof affiliateConfig.useModelV3PowerBy !== "undefined") {
                    this.showPowerBy = affiliateConfig.useModelV3PowerBy;
                  } else {
                    this.showPowerBy = false;
                  }
                }
              }
            }

            if (affiliateConfig && typeof affiliateConfig.showCF247Logo !== "undefined") {
              this.showCF247Logo = affiliateConfig.showCF247Logo;
            }
          }
        }
    }
</script>

<style scoped>
  .loaneoAdjust {
    display: flex; 
    justify-content: center;
    align-items: center;
  }
</style>